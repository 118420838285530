import { DotsVertical } from "@ignite-analytics/icons";
import { Avatar, IconButton, Menu, MenuItem, Paper, Stack, Typography, styled } from "@mui/material";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { NoPermissionTooltip } from "@/components/NoPermissionTooltip";
import { graphql } from "@/gql";
import { NoteRow_NoteFragment, Note_RoleUserFragment, Onboarding_NoteFragment } from "@/gql/graphql";

import { DeleteNoteModal } from "./DeleteNoteModal";
import { EditNote } from "./EditNote";

export interface NoteRowProps {
    isEditor: boolean;
    note: NoteRow_NoteFragment;
    creator: Note_RoleUserFragment | undefined;
    canEdit: boolean;
    setNotes: React.Dispatch<React.SetStateAction<Onboarding_NoteFragment[]>>;
}

const Span = styled("span")(({ theme }) => ({
    role: "button",
    color: theme.palette.primary.main,
    cursor: "pointer",
    paddingLeft: "4px",
    borderRadius: theme.shape.borderRadius,
}));

graphql(`
    fragment NoteRow_Note on Note {
        id
        note
        createdBy
        createdAt
        updatedBy
        updatedAt
        supplierId
    }

    fragment Note_RoleUser on RoleUser {
        id
        firstName
        lastName
    }
`);

export const NoteRow: React.FC<NoteRowProps> = ({ note, isEditor, creator, canEdit, setNotes }) => {
    const [expanded, setExpanded] = useState(false);
    const { formatMessage, formatDate } = useIntl();
    const needsExpansion = note.note.length > 400;
    const text = !needsExpansion || expanded ? note.note : `${note.note.slice(0, 400)}...`;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [editNote, setEditNote] = useState<boolean>(false);
    const [deleteNote, setDeleteNote] = useState<boolean>(false);

    let userDisplayName = "Unknown user";
    let initials = "";

    const colorOptions = ["darkorange", "radicalred", "purple", "green"];

    if (creator) {
        userDisplayName = `${creator.firstName} ${creator.lastName}`;
        initials = `${creator.firstName.charAt(0)}${creator.lastName.charAt(0)}`;
    }

    const userColor = creator
        ? colorOptions[parseInt(creator.id?.replace(/[^\d.]/g, "")) % colorOptions.length]
        : "none";

    return (
        <Paper sx={{ boxShadow: 3 }}>
            <Stack margin={2}>
                <Stack direction="row" justifyContent="space-between">
                    <Stack direction="row" alignItems="top" spacing="13px">
                        <Avatar color={userColor}>{initials}</Avatar>
                        <Typography variant="textSm">{userDisplayName}</Typography>
                    </Stack>

                    <Stack direction="row" alignItems="top" spacing={1}>
                        {note.updatedAt != note.createdAt && (
                            <Typography color="text.disabled" variant="textXs" fontWeight={200}>
                                <FormattedMessage
                                    defaultMessage="(Updated {updatedAt})"
                                    values={{
                                        updatedAt: formatDate(note.updatedAt ?? "", {
                                            day: "numeric",
                                            month: "numeric",
                                            year: "numeric",
                                        }),
                                    }}
                                />
                            </Typography>
                        )}
                        <Typography color="text.disabled" variant="textXs" fontWeight={200}>
                            {formatDate(note.createdAt, {
                                day: "numeric",
                                month: "numeric",
                                year: "numeric",
                            })}
                        </Typography>
                        <NoPermissionTooltip hasPermission={isEditor}>
                            <IconButton
                                onClick={(event) => setAnchorEl(event.currentTarget)}
                                disabled={!canEdit || !isEditor}
                            >
                                <DotsVertical />
                            </IconButton>
                            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
                                <MenuItem
                                    onClick={() => {
                                        setEditNote(true);
                                        setAnchorEl(null);
                                    }}
                                >
                                    {formatMessage({ defaultMessage: "Edit" })}
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        setDeleteNote(true);
                                        setAnchorEl(null);
                                    }}
                                >
                                    {formatMessage({ defaultMessage: "Delete" })}
                                </MenuItem>
                            </Menu>
                        </NoPermissionTooltip>
                    </Stack>
                </Stack>
                <Stack paddingX={7} marginTop={-2}>
                    {editNote ? (
                        <EditNote
                            oldNote={note}
                            onClose={() => {
                                setEditNote(false);
                            }}
                            onUpdate={(updatedNote, oldNote) => {
                                setNotes((prevNotes) => {
                                    const i = prevNotes.findIndex((n) => n.id === oldNote.id);
                                    return [...prevNotes.slice(0, i), updatedNote, ...prevNotes.slice(i + 1)];
                                });
                                setEditNote(false);
                            }}
                        />
                    ) : (
                        <Typography variant="textSm" fontWeight={400} color="textTextBody">
                            {text}
                            {needsExpansion && (
                                <Span onClick={() => setExpanded(!expanded)}>
                                    {expanded
                                        ? formatMessage({ defaultMessage: "Show less" })
                                        : formatMessage({ defaultMessage: "Show more" })}
                                </Span>
                            )}
                        </Typography>
                    )}
                </Stack>
                {deleteNote && (
                    <DeleteNoteModal
                        note={note}
                        onClose={() => {
                            setDeleteNote(false);
                        }}
                        noteProps={{
                            note: note,
                            isEditor: isEditor,
                            creator: creator,
                            canEdit: false,
                            setNotes: () => {
                                return;
                            },
                        }}
                        onDelete={() => {
                            setNotes((prevNotes) => prevNotes.filter((n) => n.id !== note.id));
                        }}
                    />
                )}
            </Stack>
        </Paper>
    );
};
