import { getRegion } from "@ignite-analytics/locale";
import { Divider, Stack, TextField, Typography, Grid2 as Grid, Autocomplete } from "@mui/material";
import { DebouncedFunc } from "lodash";
import React from "react";
import { useIntl } from "react-intl";

import { graphql } from "@/gql";
import { KnownFields_SupplierFragment } from "@/gql/graphql";

import { countryCodes } from "./countryCodes";
interface Props {
    onUpdate: DebouncedFunc<(fieldId: string, fieldData: string) => void>;
    edit: boolean;
    supplier: KnownFields_SupplierFragment;
    knownFieldNames: Record<string, string>;
}

graphql(`
    fragment KnownFields_Supplier on Supplier {
        name
        orgNumber
        country
    }
`);

interface knownFields {
    key: string;
    value: string;
    keyName: string;
}

export const KnownFields: React.FC<Props> = ({ onUpdate, edit, supplier, knownFieldNames }) => {
    const { formatMessage } = useIntl();
    const region = getRegion();
    const fields: knownFields[] = [
        {
            key: "name",
            value: supplier?.name ?? "",
            keyName: knownFieldNames["name"] ?? formatMessage({ defaultMessage: "Name" }),
        },
        {
            key: "reg_nr",
            value: supplier?.orgNumber ?? "",
            keyName: knownFieldNames["reg_nr"] ?? formatMessage({ defaultMessage: "Registration number" }),
        },
        {
            key: "country",
            value: supplier?.country ?? "",
            keyName: knownFieldNames["country"] ?? formatMessage({ defaultMessage: "Country" }),
        },
    ];

    const getEditor = (field: knownFields) => {
        if (field.key === "country") {
            return (
                <Autocomplete
                    onChange={(_e, value) => onUpdate(field.key, value?.code ?? "")}
                    options={countryCodes}
                    getOptionLabel={(option) =>
                        `${option.code} - ${region == "no-NB" ? option.norwegian : option.name}`
                    }
                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                    size="small"
                    value={countryCodes.find((country) => country.code === field.value)}
                    filterOptions={(options, params) => {
                        const inputValue = params.inputValue.toLowerCase();
                        return options.filter((option) => {
                            const codeMatches = option.code.toLowerCase().includes(inputValue);
                            const nameMatches = (region === "no-NB" ? option.norwegian : option.name)
                                .toLowerCase()
                                .split(" ")
                                .some((word) => word.startsWith(inputValue));
                            return codeMatches || nameMatches;
                        });
                    }}
                />
            );
        }
        return (
            <TextField
                size="small"
                variant="outlined"
                type="text"
                defaultValue={field.value}
                onChange={(fieldVal) => onUpdate(field.key, fieldVal.target.value)}
                fullWidth
            />
        );
    };
    return (
        <>
            {fields.map((field) => (
                <>
                    <Stack justifyContent="center" key={field.key} sx={{ height: "52px", width: "100%" }}>
                        <Grid container alignItems="center" width="100%">
                            <Grid size={{ xs: 4 }} component="div" alignContent="center" paddingLeft={3}>
                                <Typography variant="textSm">{field.keyName}</Typography>
                            </Grid>

                            <Grid size={{ xs: 8 }} component="div" textAlign="end" paddingRight={3}>
                                {edit ? getEditor(field) : <Typography variant="textSm">{field.value}</Typography>}
                            </Grid>
                        </Grid>
                    </Stack>
                    <Divider orientation="horizontal" flexItem />
                </>
            ))}
        </>
    );
};
