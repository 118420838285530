import { useMutation } from "@apollo/client";
import { ShapeIcon } from "@ignite-analytics/components";
import { ExclamationTriangle, File } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import { Button, Dialog, DialogActions, DialogContent, Stack, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import { DeleteModal_SupplierFileFragment, Onboarding_GetSupplierDocument } from "@/gql/graphql";
import { getNameAndContentType } from "@/lib/files";
import { useAlert } from "@/providers";

type Props = {
    file: DeleteModal_SupplierFileFragment;
    open: boolean;
    setOpen: (open: boolean) => void;
    setIsLoading: (open: boolean) => void;
    supplierId: string;
};

const DeleteModal_DeleteFileMutation = graphql(`
    mutation DeleteModal_DeleteFileMutation($input: DeleteSupplierFileInput!) {
        deleteSupplierFile(input: $input) {
            ok
        }
    }
`);

graphql(`
    fragment DeleteModal_SupplierFile on SupplierFile {
        name
        supplierId
        metaJson
    }
`);

export const DeleteModal: React.FC<Props> = ({ file, open, setOpen, setIsLoading, supplierId }) => {
    const [deleteFile] = useMutation(DeleteModal_DeleteFileMutation);
    const { originalFileName } = getNameAndContentType(file);
    const { alertUser } = useAlert();
    const { formatMessage } = useIntl();
    const handleDeleteFile = (file: DeleteModal_SupplierFileFragment) => {
        setIsLoading(true);
        deleteFile({
            variables: {
                input: {
                    supplierId: file.supplierId,
                    fileName: file.name,
                },
            },
            onCompleted: () => {
                alertUser({
                    value: formatMessage({
                        defaultMessage: "Document deleted successfully",
                        description: "Snackbar success message for deleting supplier document",
                    }),
                    severity: "success",
                });
                setIsLoading(false);
            },
            onError: (error) => {
                alertUser({
                    value: formatMessage({
                        defaultMessage: "An error occurred while deleting this document",
                        description: "Snackbar error message for deleting supplier document",
                    }),
                    severity: "error",
                });
                Sentry.captureException(error, {
                    tags: { app: "supplier-profile-app", message: "Failed to delete supplier file" },
                });
                setIsLoading(false);
            },
            refetchQueries: [Onboarding_GetSupplierDocument],
        });
    };
    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogContent>
                <Stack spacing={1.5} alignContent="center">
                    <Stack alignItems="center">
                        <ShapeIcon color="warning" size="medium">
                            <ExclamationTriangle />
                        </ShapeIcon>
                    </Stack>
                    <Stack spacing={1} alignItems="center">
                        <Typography variant="textLg" fontWeight={500}>
                            <FormattedMessage defaultMessage="Delete document" />
                        </Typography>
                        <Typography variant="textSm" color="text.textHelper">
                            <FormattedMessage defaultMessage="Do you want to proceed and delete this document?" />
                        </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <ShapeIcon color="gray" size="small">
                            <File fontSize="inherit" />
                        </ShapeIcon>
                        <Typography
                            sx={{ overflowWrap: "break-word", wordBreak: "break-all", textOverflow: "ellipsis" }}
                            variant="textMd"
                            fontWeight={500}
                        >
                            {decodeURIComponent(originalFileName)}
                        </Typography>
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack direction="row" width="100%" spacing={2} justifyContent="space-between" alignItems="center">
                    <Button sx={{ width: "50%" }} onClick={() => setOpen(false)} color="secondary">
                        <FormattedMessage defaultMessage="Cancel" />
                    </Button>
                    <Button
                        onClick={async () => {
                            setOpen(false);
                            handleDeleteFile(file);
                            track("Onboarding: Deleted Document", { supplierId });
                        }}
                        color="primary"
                        sx={{ width: "50%", whiteSpace: "nowrap" }}
                    >
                        <FormattedMessage defaultMessage="Delete document" />
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};
