import { useMutation } from "@apollo/client";
import { ShapeIcon } from "@ignite-analytics/components";
import { FolderImage, Trash, X } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Stack,
    Typography,
    Autocomplete,
    TextField,
    Box,
    IconButton,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { FormattedMessage, useIntl } from "react-intl";

import { ACCEPTED_FILE_EXTENSIONS, getContentType, getFileExtension, MAX_FILE_SIZE } from "@/lib/files";
import { useAlert } from "@/providers";
import { usePolling } from "@/providers/PollingContext";
import { useUser } from "@/providers/UserContext";

import { Documents_CreateUploadURL } from "../UploadButton";

import { DocumentTypeOption, documentTypes } from "./types";

interface UploadModalProps {
    open: boolean;
    onClose: () => void;
    supplierId: string;
    refetch: () => void;
}

interface FileWithMetadata {
    file: File;
    documentType: DocumentTypeOption | null;
    expiryDate: Date | null;
}

export const UploadModal: React.FC<UploadModalProps> = ({ open, onClose, supplierId, refetch }) => {
    const { formatMessage } = useIntl();
    const { alertUser } = useAlert();
    const { handleStartPolling } = usePolling();

    const [uploadFile] = useMutation(Documents_CreateUploadURL);

    const user = useUser();

    const [files, setFiles] = useState<FileWithMetadata[]>([]);
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles: File[]) => {
            const newFiles = acceptedFiles.map((file) => ({
                file,
                documentType: null,
                expiryDate: null,
            }));
            setFiles((prev) => [...prev, ...newFiles]);
        },
        accept: ACCEPTED_FILE_EXTENSIONS.split(",").reduce(
            (acc, ext) => {
                acc[ext] = [];
                return acc;
            },
            {} as Record<string, string[]>
        ),
        noClick: false,
    });

    const handleUploadFiles = async () => {
        if (!files.length) return;
        const blockedFiles: string[] = [];
        const approvedFiles = files.reduce((acc: { name: string; fileWithMetadata: FileWithMetadata }[], f) => {
            const fileName = f.file.name;
            if (f.file.size > MAX_FILE_SIZE) {
                blockedFiles.push(fileName);
            } else {
                acc.push({ name: fileName, fileWithMetadata: f });
            }
            return acc;
        }, []);

        if (blockedFiles.length > 0) {
            const blockedFilesList = blockedFiles.join(", ");
            alertUser({
                value: formatMessage(
                    {
                        defaultMessage: "{files} could not be uploaded because of file size (larger than 20MB).",
                        description: "Error uploading file",
                    },
                    { files: blockedFilesList }
                ),
                severity: "error",
            });
            track("Onboarding: Attempted to Upload Too Large Document", { supplierId });
        }
        const uploadPromises = approvedFiles.map(async (approvedFile) => {
            // create signed upload url
            const expiryDate = approvedFile.fileWithMetadata.expiryDate
                ? dayjs(approvedFile.fileWithMetadata.expiryDate).startOf("day").toISOString()
                : undefined;

            const data = await uploadFile({
                variables: {
                    input: {
                        supplierId: supplierId,
                        fileName: approvedFile.name,
                        fileMetaJson: JSON.stringify({
                            origin: "supplier-page",
                            doctype: approvedFile.fileWithMetadata.documentType?.tag ?? "",
                            ...(expiryDate && { expirydate: expiryDate }),
                        }),
                    },
                },
                onError: () => {
                    alertUser({
                        value: formatMessage(
                            {
                                defaultMessage: "Error uploading file {filename}",
                                description: "Error uploading file",
                            },
                            { filename: approvedFile.name }
                        ),
                        severity: "error",
                    });
                },
            });

            const url = data.data?.createSupplierFileUploadUrl.url;
            if (!url) return false;

            const fileExtension = getFileExtension(approvedFile.name);
            if (!fileExtension) return false;
            const re = new RegExp(`[&/\\#,+()$~%'":*?<>{}]`, "g");
            const sanitizedFileName = approvedFile.name.replace(re, "_");
            const encodedFileName = encodeURIComponent(sanitizedFileName);
            const headers: { [key: string]: string } = {
                "x-goog-meta-origin": "supplier-page",
                "x-goog-content-length-range": "0,20971520",
                "Content-Type": getContentType(fileExtension),
                "x-goog-meta-av_scanned": "FALSE",
                "x-goog-meta-originalfilename": encodedFileName,
                "Content-Disposition": `attachment; filename*=UTF-8''${encodedFileName}`,
                "x-goog-meta-doctype": approvedFile.fileWithMetadata.documentType?.tag ?? "",
                ...(expiryDate && { "x-goog-meta-expirydate": expiryDate }),
                "x-goog-meta-uploadedby": user.id,
            };

            // upload the file to the signed url
            return fetch(url, {
                method: "PUT",
                headers: headers,
                body: approvedFile.fileWithMetadata.file,
            }).catch(() => {
                alertUser({
                    value: formatMessage(
                        {
                            defaultMessage: "Error uploading file {file}",
                            description: "Error uploading file",
                        },
                        { file: approvedFile.fileWithMetadata.file.name }
                    ),
                    severity: "error",
                });
            });
        });
        await Promise.all(uploadPromises).finally(() => {
            refetch();
            handleStartPolling(3000, 21000);
            track("Onboarding: Uploaded Document(s)", {
                types: files.map((f) => f.documentType?.tag),
                expiryDates: files.map((f) => f.expiryDate),
                supplierId,
            });
            onClose();
            setFiles([]);
        });
    };

    const updateFileMetadata = (index: number, updates: Partial<FileWithMetadata>) => {
        setFiles((prevFiles) => prevFiles.map((file, i) => (i === index ? { ...file, ...updates } : file)));
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">
                        <FormattedMessage defaultMessage="Add new documents" />
                    </Typography>
                    <Button color="ghostGray" onClick={onClose} sx={{ minWidth: "auto", p: 1 }}>
                        <X />
                    </Button>
                </Stack>
                <Typography variant="textSm" color="textTextHelper" fontWeight={400}>
                    <FormattedMessage defaultMessage="Select a document type and add an expiry date to keep track of your supplier documents." />
                </Typography>
            </DialogTitle>

            <DialogContent>
                <Stack spacing={3} sx={{ mt: 2 }}>
                    <div
                        {...getRootProps()}
                        style={{
                            border: "2px dashed #ccc",
                            borderRadius: "4px",
                            padding: "20px",
                            textAlign: "center",
                            cursor: "pointer",
                        }}
                    >
                        <input {...getInputProps()} />
                        <Stack spacing={1} padding={2} alignItems="center" justifyContent="center">
                            <ShapeIcon
                                color="accent"
                                variant="outline"
                                size="medium"
                                icon={<FolderImage fontSize="inherit" />}
                            />
                            <Typography>
                                <FormattedMessage defaultMessage="Drag and drop files here, or click to select" />
                            </Typography>
                            <Typography variant="textXs" color="textTextHelper">
                                <FormattedMessage defaultMessage="Accepts: PDF, DOC, DOCX, XLS, XLSX, PPT, PPTX, JPG, JPEG, PNG" />
                            </Typography>
                        </Stack>
                    </div>
                    <Stack spacing={5}>
                        {files.map((fileData, index) => (
                            <Stack key={index} spacing={1}>
                                <Stack key={index} direction="row" alignItems="center" justifyContent="space-between">
                                    <Stack>
                                        <Typography color="textTextPrimary" fontSize="textSm">
                                            {fileData.file.name}
                                        </Typography>
                                        <Typography color="textTextHelper" variant="textXs">
                                            {`(${(fileData.file.size / (1024 * 1024)).toFixed(2)}MB)`}
                                        </Typography>
                                    </Stack>
                                    <IconButton
                                        size="small"
                                        color="secondary"
                                        onClick={() => setFiles(files.filter((_, i) => i !== index))}
                                    >
                                        <Trash color="error" />
                                    </IconButton>
                                </Stack>
                                <Stack direction="row" spacing={1}>
                                    <Autocomplete
                                        options={documentTypes}
                                        value={fileData.documentType}
                                        size="small"
                                        onChange={(_, newValue) =>
                                            updateFileMetadata(index, { documentType: newValue })
                                        }
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => (
                                            <TextField
                                                required
                                                {...params}
                                                label={<FormattedMessage defaultMessage="Document type" />}
                                                placeholder={formatMessage({
                                                    defaultMessage: "Select a document type",
                                                })}
                                            />
                                        )}
                                        renderOption={(props, option) => (
                                            <Box component="li" {...props} key={option.tag}>
                                                <Stack>
                                                    <Typography>{option.name.split("-")[0]}</Typography>
                                                    {option.name.includes("-") && (
                                                        <Typography variant="textXs" color="textTextHelper">
                                                            {option.name.split("-")[1]}
                                                        </Typography>
                                                    )}
                                                </Stack>
                                            </Box>
                                        )}
                                        sx={{ width: "70%" }}
                                    />
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label={<FormattedMessage defaultMessage="Expiry date" />}
                                            value={fileData.expiryDate ? dayjs(fileData.expiryDate) : null}
                                            onChange={(newValue) =>
                                                updateFileMetadata(index, { expiryDate: newValue?.toDate() ?? null })
                                            }
                                            format="DD/MM/YYYY"
                                            slotProps={{
                                                textField: {
                                                    sx: { width: "40%" },
                                                    InputProps: {
                                                        sx: {
                                                            "& .MuiInputAdornment-root": {
                                                                order: -1,
                                                                marginLeft: 0,
                                                            },
                                                        },
                                                    },
                                                },
                                            }}
                                        />
                                    </LocalizationProvider>
                                </Stack>
                            </Stack>
                        ))}
                    </Stack>
                </Stack>
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    <FormattedMessage defaultMessage="Cancel" />
                </Button>
                <Button
                    variant="contained"
                    disabled={files.length === 0 || files.some((f) => !f.documentType)}
                    onClick={handleUploadFiles}
                >
                    <FormattedMessage defaultMessage="Confirm & add" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};
