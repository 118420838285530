import { Grid, Stack, Typography, Divider } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { Certification, CodeOfConduct } from "@/gql/graphql";

import { CertsInfo } from "./CertsInfo";
import { CocInfoChip } from "./CocInfoChip";

interface DocumentFieldsProps {
    certifications: Certification[];
    codeOfConducts: CodeOfConduct[];
}
export const DocumentFields = ({ certifications, codeOfConducts }: DocumentFieldsProps) => {
    return (
        <>
            <Stack justifyContent="center" sx={{ height: "52px", width: "100%" }}>
                <Grid container alignItems="center" width="100%">
                    <Grid xs={4} alignContent="center" paddingLeft={3}>
                        <Typography variant="textSm">
                            <FormattedMessage defaultMessage="Certifications" description="Certifications field name" />
                        </Typography>
                    </Grid>
                    <Grid xs={8} textAlign="end" paddingRight={3} display="flex" justifyContent="flex-end">
                        <CertsInfo certs={certifications} />
                    </Grid>
                </Grid>
            </Stack>
            <Divider orientation="horizontal" flexItem />
            <Stack justifyContent="center" sx={{ height: "52px", width: "100%" }}>
                <Grid container alignItems="center" width="100%">
                    <Grid xs={4} alignContent="center" paddingLeft={3}>
                        <Typography variant="textSm">
                            <FormattedMessage
                                defaultMessage="Code of Conduct"
                                description="Code of Conduct field name"
                            />
                        </Typography>
                    </Grid>
                    <Grid xs={8} textAlign="end" paddingRight={3} display="flex" justifyContent="flex-end">
                        <CocInfoChip codeOfConducts={codeOfConducts} />
                    </Grid>
                </Grid>
            </Stack>
            <Divider orientation="horizontal" flexItem />
        </>
    );
};
