import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { Chip, LinearProgress, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import { DocumentsList_AssessmentsFileFragment, DocumentsList_SupplierFileFragment } from "@/gql/graphql";
import { getNameAndContentType } from "@/lib/files";

import { getShortDocumentTypeName } from "../UploadModal/types";

import { DocumentActions } from "./DocumentActions";
import { ExpirationChip } from "./ExpirationChip";

export type DocumentListFiles = DocumentsList_SupplierFileFragment | DocumentsList_AssessmentsFileFragment;

type Props = {
    files: DocumentListFiles[];
    isLoading: boolean;
    supplierId: string;
    isEditor: boolean;
};

graphql(`
    fragment DocumentsList_SupplierFile on SupplierFile {
        name
        createdAt
        metaJson
        size
        supplierId
        ...DocumentsActions_SupplierFile
    }

    fragment DocumentsList_AssessmentsFile on AssessmentsFile {
        name
        createdAt
        metaJson
        size
        referenceId
    }
`);

const isScanning = (file: DocumentListFiles) => {
    if (!file.metaJson) return false;
    const { av_scanned } = JSON.parse(file.metaJson);
    return av_scanned === "FALSE";
};

export const DocumentsList: React.FC<Props> = ({ files, supplierId, isEditor }) => {
    const { formatMessage, formatDate } = useIntl();
    const hasNewUploadToggle = useFeatureToggle("new-upload");

    const convertDocumentOrigin = (metaJson: string) => {
        const { origin } = JSON.parse(metaJson);
        switch (origin) {
            case "supplier-page":
                return formatMessage({
                    defaultMessage: "Upload",
                    description: "Documents uploaded from supplier-page",
                });
            case "assessment":
                return formatMessage({
                    defaultMessage: "Assessments",
                    description: "Documents uploaded from assessment answer",
                });
            default:
                return formatMessage({ defaultMessage: "Unknown", description: "Unknown document upload origin" });
        }
    };

    return (
        <Stack spacing={1}>
            {files?.map((file) => {
                const { originalFileName } = getNameAndContentType(file);
                const metaJson = file.metaJson ? JSON.parse(file.metaJson) : {};
                const docType = metaJson.doctype;
                const expiryDate = metaJson.expirydate;
                return isScanning(file) ? (
                    <Paper sx={{ padding: 2 }}>
                        <Stack spacing={1}>
                            <LinearProgress variant="indeterminate" />
                            <Typography
                                variant="textSm"
                                fontWeight={500}
                                sx={{ overflowWrap: "break-word", wordBreak: "break-all" }}
                            >
                                <FormattedMessage
                                    defaultMessage="Uploading {file}..."
                                    values={{ file: decodeURIComponent(originalFileName) }}
                                />
                            </Typography>
                        </Stack>
                    </Paper>
                ) : (
                    <Paper key={`${file.name}+${file.metaJson}`} sx={{ padding: 2 }}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Stack spacing={1} pr={2}>
                                <Typography
                                    variant="textSm"
                                    fontWeight={500}
                                    sx={{ overflowWrap: "break-word", wordBreak: "break-all" }}
                                >
                                    {decodeURIComponent(originalFileName)}
                                </Typography>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    {getShortDocumentTypeName(docType) !== "" && (
                                        <Chip
                                            label={getShortDocumentTypeName(docType)}
                                            color="primary"
                                            size="small"
                                            clickable={false}
                                        />
                                    )}
                                    <Typography variant="textXs">
                                        {file.createdAt !== null ? formatDate(file.createdAt) : ""}
                                    </Typography>
                                    {file.metaJson && (
                                        <Typography variant="textXs">{convertDocumentOrigin(file.metaJson)}</Typography>
                                    )}
                                </Stack>
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                {hasNewUploadToggle && <ExpirationChip expiryDate={expiryDate} />}
                                <DocumentActions isEditor={isEditor} file={file} supplierId={supplierId} />
                            </Stack>
                        </Stack>
                    </Paper>
                );
            })}
        </Stack>
    );
};
