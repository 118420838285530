import { useMutation } from "@apollo/client";
import { Trash, ArrowDownTray, DotsHorizontal } from "@ignite-analytics/icons";
import { CircularProgress, IconButton, Menu, MenuItem, Stack } from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import { NoPermissionTooltip } from "@/components/NoPermissionTooltip";
import { graphql } from "@/gql";
import { getNameAndContentType } from "@/lib/files";
import { track } from "@/lib/track";

import { DeleteModal } from "./DeleteModal";

import { DocumentListFiles } from "./index";

type ActionProps = {
    file: DocumentListFiles;
    supplierId: string;
    isEditor: boolean;
};

const documentActions_SupplierFileDownloadUrlMutation = graphql(`
    mutation DocumentsActions_CreateSupplierFileDownloadURL($input: CreateSupplierFileDownloadUrlInput!) {
        createSupplierFileDownloadUrl(input: $input) {
            url
        }
    }
`);

const documentActions_AssessmentsDownloadUrlMutation = graphql(`
    mutation DocumentsActions_CreateAssessmentsDownloadURL($input: CreateAssessmentsDownloadURLInput!) {
        createAssessmentsDownloadURL(input: $input) {
            url
        }
    }
`);

graphql(`
    fragment DocumentsActions_SupplierFile on SupplierFile {
        name
        ...DeleteModal_SupplierFile
    }
`);

export const DocumentActions: React.FC<ActionProps> = ({ file, supplierId, isEditor }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [downloadSupplierFile] = useMutation(documentActions_SupplierFileDownloadUrlMutation);
    const [downloadAssessmentsFile] = useMutation(documentActions_AssessmentsDownloadUrlMutation);

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    async function getFileUrl(selectedFile: DocumentListFiles) {
        switch (selectedFile.__typename) {
            case "SupplierFile": {
                const supplierUrlData = await downloadSupplierFile({
                    variables: {
                        input: {
                            supplierId: selectedFile.supplierId,
                            fileName: selectedFile.name,
                        },
                    },
                });
                return supplierUrlData?.data?.createSupplierFileDownloadUrl?.url ?? "";
            }
            case "AssessmentsFile": {
                const assessmentsUrlData = await downloadAssessmentsFile({
                    variables: {
                        input: {
                            referenceId: selectedFile.referenceId,
                            fileName: selectedFile.name,
                        },
                    },
                });
                return assessmentsUrlData.data?.createAssessmentsDownloadURL?.url ?? "";
            }
            default:
                return Promise.resolve("");
        }
    }

    const handleDownloadFile = async (selectedFile: DocumentListFiles) => {
        const { originalFileName, contentType } = getNameAndContentType(selectedFile);
        const url = await getFileUrl(selectedFile);
        if (!url) {
            return Promise.resolve();
        }

        const headerSet = {
            "Content-Disposition": `attachment; filename*=UTF-8''${originalFileName}`,
            "Content-Type": contentType,
            "X-Content-Type-Options": "nosniff",
        };

        const fileBlob = fetch(url, {
            method: "GET",
            headers: headerSet,
        })
            .then((response) => {
                return response.blob();
            })
            .catch((err) => {
                throw err;
            });
        return fileBlob
            .then((blob) => {
                const blobUrl = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = blobUrl;
                link.setAttribute("download", `${originalFileName}`);
                link.setAttribute("target", "_blank");
                document.body.appendChild(link);
                link.click();
                link.parentNode?.removeChild(link);
                track("Onboarding Page: Downloaded Document", { supplierId });
            })
            .catch((err) => {
                throw err;
            });
    };

    return (
        <Stack direction="row" alignItems="center" justifyContent="center">
            {isLoading ? (
                <Stack>
                    <CircularProgress size={24} />
                </Stack>
            ) : (
                <IconButton className={"menu-icon" + file.name} onClick={handleMenuClick}>
                    <DotsHorizontal />
                </IconButton>
            )}
            <Menu
                key={"menu-" + file.name}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                sx={{ "& .MuiPaper-root": { minWidth: "8rem" } }}
            >
                <MenuItem
                    key={"download-" + file.name}
                    onClick={async () => {
                        await handleDownloadFile(file);
                        handleClose();
                    }}
                >
                    <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                        <FormattedMessage defaultMessage="Download" />
                        <ArrowDownTray sx={{ paddingLeft: 1 }} fontSize="small" />
                    </Stack>
                </MenuItem>
                {file.__typename === "SupplierFile" && (
                    <NoPermissionTooltip hasPermission={isEditor}>
                        <MenuItem
                            key={"delete-" + file.name}
                            onClick={() => {
                                setOpenDeleteModal(true);
                                handleClose();
                            }}
                            disabled={!isEditor}
                        >
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                                <FormattedMessage defaultMessage="Delete" />
                                <Trash sx={{ paddingLeft: 1 }} fontSize="small" />
                            </Stack>
                        </MenuItem>
                    </NoPermissionTooltip>
                )}
            </Menu>
            {file.__typename === "SupplierFile" && (
                <DeleteModal
                    file={file}
                    open={openDeleteModal}
                    setOpen={setOpenDeleteModal}
                    setIsLoading={setIsLoading}
                    supplierId={supplierId}
                />
            )}
        </Stack>
    );
};
