import { useQuery } from "@apollo/client";
import { ChevronDown, X } from "@ignite-analytics/icons";
import {
    AccordionSummary as MuiAccordionSummary,
    Button,
    Chip,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Skeleton,
    Stack,
    Typography,
} from "@mui/material";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom-v5-compat";

import { graphql } from "@/gql";
import { usePermission } from "@/lib/permissions";
import { PollingContextProvider } from "@/providers/PollingContext";

import { Layout } from "../Layout";
import { NoPermissionTooltip } from "../NoPermissionTooltip";

import { ApproveModal } from "./ApproveModal";
import { ContractModal } from "./ContractModal";
import { OnboardingAssessments } from "./OnboardingAssessment";
import { OnboardingChecklist } from "./OnboardingChecklist";
import { OnboardingContacts } from "./OnboardingContacts";
import { OnboardingDocuments } from "./OnboardingDocuments";
import { OnboardingNotes } from "./OnboardingNotes";
import { SupplierInformation } from "./SupplierInformation";

graphql(`
    fragment Onboarding_Supplier on Supplier {
        id
        name
        country
        orgNumber
        tasks {
            id
            ...Checklist_Task
        }
        files {
            ...DocumentsList_SupplierFile
        }
        assessmentsFiles {
            ...DocumentsList_AssessmentsFile
        }
        onboarding {
            status
            approverId
            evaluatedAt
        }
        risk {
            social
            industry
            geography
        }
        contracts {
            id
        }
        ...OnboardingNotes_Supplier
        ...Documents_Supplier
        ...SupplierInformation_Supplier
    }
`);

const GetSupplier_OnboardingQuery = graphql(`
    query Onboarding_GetSupplier($input: GetSupplierInput!) {
        getSupplier(input: $input) {
            supplier {
                ...Onboarding_Supplier
            }
        }
    }
`);

interface NewSupplierProps {
    open: boolean;
    handleClosePage: () => void;
}

interface AccordionSummaryProps {
    id: string;
    ariaControls: string;
    children: React.ReactNode;
}

export const AccordionSummary: React.FC<AccordionSummaryProps> = ({ id, ariaControls, children }) => {
    return (
        <MuiAccordionSummary
            aria-controls={ariaControls}
            id={id}
            expandIcon={<ChevronDown />}
            sx={{
                background: (theme) => theme.palette.background.paper, // *
                position: "sticky", // sticky scrolling summary headers
                top: 0, // not quite ready for production
                zIndex: 1, // *
                flexDirection: "row-reverse",
                borderRadius: 1,
                "& .MuiAccordionSummary-content": {
                    marginLeft: 1,
                },
                "& .MuiAccordionSummary-content.Mui-expanded": {
                    marginLeft: 1,
                },
            }}
        >
            {children}
        </MuiAccordionSummary>
    );
};

export const OnboardingSupplier: React.FC<NewSupplierProps> = ({ open, handleClosePage }) => {
    const [expanded, setExpanded] = useState<string | false>("panel1");
    const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
    const [isContractModalOpen, setIsContractModalOpen] = useState(false);

    const { id } = useParams<{ id: string }>() as { id: string };

    const isEditor = usePermission({ object: "general", relation: "write" });

    const { data, loading, error, refetch, startPolling, stopPolling } = useQuery(GetSupplier_OnboardingQuery, {
        variables: {
            input: {
                id: id,
            },
        },
    });
    const supplier = data?.getSupplier?.supplier;
    if (loading) {
        return <Skeleton variant="rectangular" height={400} />;
    }

    if (error || !supplier) {
        return (
            <Typography>
                <FormattedMessage defaultMessage="Error loading supplier" description="Error loading supplier" />
            </Typography>
        );
    }

    const handleChange = (panel: string) => (_: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <PollingContextProvider startPolling={startPolling} stopPolling={stopPolling}>
            <Layout open={open} handleClose={handleClosePage} maxWidth="md">
                <DialogTitle mb={2}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Stack direction="row" justifyContent="left" alignItems="center" spacing={1}>
                            <Typography variant="displayXs" marginRight={2}>
                                {supplier.name}
                            </Typography>
                            {supplier.onboarding?.status == "IN_ONBOARDING" ? (
                                <Chip
                                    label={<FormattedMessage defaultMessage="In onboarding" />}
                                    color="warning"
                                    size="small"
                                />
                            ) : supplier.onboarding?.status == "APPROVED" ? (
                                <Chip
                                    label={<FormattedMessage defaultMessage="Approved" />}
                                    color="success"
                                    size="small"
                                />
                            ) : (
                                <Chip
                                    label={<FormattedMessage defaultMessage="Rejected" />}
                                    color="error"
                                    size="small"
                                />
                            )}
                        </Stack>
                        <IconButton onClick={() => handleClosePage()}>
                            <X />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={8}>
                            <Stack spacing={2} overflow="auto" maxHeight="65vh">
                                <OnboardingAssessments
                                    open={expanded === "panel1"}
                                    onChange={handleChange("panel1")}
                                    supplierId={supplier.id}
                                    isEditor={isEditor}
                                />
                                <OnboardingDocuments
                                    open={expanded === "panel2"}
                                    onChange={handleChange("panel2")}
                                    supplier={supplier}
                                    isEditor={isEditor}
                                    refetch={refetch}
                                />
                                <SupplierInformation
                                    open={expanded === "panel3"}
                                    onChange={handleChange("panel3")}
                                    supplier={supplier}
                                    isEditor={isEditor}
                                />
                                <OnboardingNotes
                                    expanded={expanded === "panel4"}
                                    onChange={handleChange("panel4")}
                                    isEditor={isEditor}
                                    supplier={supplier}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Stack spacing={2}>
                                <OnboardingChecklist
                                    tasks={supplier.tasks ?? []}
                                    supplierId={supplier.id}
                                    isEditor={isEditor}
                                />
                                <OnboardingContacts supplierId={supplier.id} isEditor={true} />
                            </Stack>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <NoPermissionTooltip hasPermission={isEditor}>
                        <Button color="primary" onClick={() => setIsApproveModalOpen(true)} disabled={!isEditor}>
                            <FormattedMessage defaultMessage="Finalize onboarding" description="Finalize onboarding" />
                        </Button>
                    </NoPermissionTooltip>
                </DialogActions>
                <ContractModal
                    open={isContractModalOpen}
                    onClose={() => {
                        setIsContractModalOpen(false);
                        setIsApproveModalOpen(true);
                    }}
                    prefilledSupplier={{ name: supplier.name, id: supplier.id }}
                    onContractAdded={() => {
                        setIsContractModalOpen(false);
                        setIsApproveModalOpen(true);
                    }}
                />
                <ApproveModal
                    open={isApproveModalOpen}
                    onClose={() => {
                        refetch();
                        setIsApproveModalOpen(false);
                    }}
                    onClickAddContract={() => {
                        setIsApproveModalOpen(false);
                        setIsContractModalOpen(true);
                    }}
                    supplierName={supplier.name}
                    supplierId={supplier.id}
                    supplierCountry={supplier.country}
                    supplierOrgNumber={supplier.orgNumber}
                    loading={loading}
                    hasContract={supplier.contracts && supplier.contracts?.length > 0 ? true : false}
                    refetch={refetch}
                />
            </Layout>
        </PollingContextProvider>
    );
};
